<template>
  <!-- Breadcrumb -->
  <div class="main-breadcrumb">
    <ul class="bc-list">
      <li class="list-active">
        <router-link to="/">
          <i class="fas fa-home"></i> {{ $t("cockpit") }}
        </router-link>
      </li>
      <li
        v-for="crumb in crumbs"
        v-bind:key="crumb.path"
        v-bind:class="crumb.class"
      >
        <router-link v-bind:to="crumb.path">{{ $t(crumb.text) }}</router-link>
      </li>
    </ul>
  </div>
  <!-- ./Breadcrumb -->
</template>

<script>
export default {
  name: "BreadCrumb",
  computed: {
    crumbs: function () {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();

      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        if (!this.$route.params.id) {
          idx++;
        }
        const matchedRoute = this.$route.matched[idx];
        const matchedBreadCrumb = matchedRoute
          ? matchedRoute.meta.breadCrumb
          : null;

        if (matchedBreadCrumb) {
          const data = {};
          data.path = matchedRoute.meta.edit
            ? this.$route.path
            : matchedRoute.path;

          data.text = matchedRoute.meta.breadCrumb || path;

          breadcrumbArray.push(data);
        }
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },
  },
  watch: {
    "$i18n.locale": function () {
      const lastCrumb = this.crumbs[this.crumbs.length - 1];
      if (lastCrumb) {
        document.title = this.$t(lastCrumb.text);
      }
    },
  },
  mounted() {
    const lastCrumb = this.crumbs[this.crumbs.length - 1];
    if (lastCrumb) {
      document.title = this.$t(lastCrumb.text);
    }
  },
};
</script>

<style lang="scss">
.bc-list {
  margin: 0;
  padding: 0;
}

.bc-list li {
  display: inline-block;
  list-style: none;
  margin-left: 1rem;
}

.bc-list.text-dark li a {
  color: #2f3542 !important;
}

.bc-list li a {
  text-decoration: none;
  color: #2f35428f;
  font-size: 15px;
  transition: 0.4s all;
}

.list-active > a,
.bc-list li:hover > a {
  color: #2f3542 !important;
}

.bc-list li:not(:last-child)::after {
  content: "\f054";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  margin-left: 1rem;
  color: #2f35428f !important;
}

.main-breadcrumb {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
